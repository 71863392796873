import React, { PropsWithChildren } from 'react';

export interface ModalProps {
  active: boolean
  showCloseButton?: boolean
  onClose?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export class Modal extends React.PureComponent<PropsWithChildren<ModalProps>> {
  render() {
    const { children, active, showCloseButton, onClose } = this.props;
    return (
      <div className={`modal ${active ? 'is-active': ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          {children}
        </div>
        {
          showCloseButton ?
          <button onClick={onClose} className="modal-close is-large" aria-label="close"></button> :
          null
        }
      </div>
    );
  }
}