import React, { FunctionComponent, Component, ReactType } from "react"
import { Route, Redirect, RouteProps } from "react-router"
import { useLoggedIn } from "../hooks/useLoggedIn";

export interface PrivateRouteProps extends RouteProps {
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({component: Component, ...rest}) => {  
  const loggedIn = useLoggedIn();
  return (
    <Route
      {...rest}
      render={(props) => loggedIn === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
    />
  )
}