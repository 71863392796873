import { gql, useQuery, QueryHookOptions } from '@apollo/client';
import { User } from '../../types/user';
import { useState, useEffect } from 'react';
import { useGraphQLData } from './helper';

export const QUERY_USER_PROFILE = gql`
query userProfile {
  userProfile {
    id,
    name,
    email,
    createdAt,
    connectedAt
  }
}`;

export function useUserProfileQuery<A = any, R = Record<string, any>>(options: QueryHookOptions<A, R> = {}) {
  return useQuery(QUERY_USER_PROFILE, options);
}

export function useUserProfile<A = any, R = Record<string, any>>(options: QueryHookOptions<A, R> = {}) {
  const { data, loading, error } = useGraphQLData<User>(
    QUERY_USER_PROFILE, 'userProfile', {id: '', email: ''}, options
  );
  return { user: data, loading, error };
}