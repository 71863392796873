import { useQuery, DocumentNode, QueryHookOptions } from "@apollo/client";
import { useState, useEffect } from "react";

export function useGraphQLData<T, A = any, R = Record<string, any>>(q: DocumentNode, key: string, defaultValue: T, options: QueryHookOptions<A, R> = {}) {
  const query = useQuery(q, options);
  const [ data, setData ] = useState<T>(defaultValue);
  useEffect(() => {
    setData(query.data ? query.data[key] as T : defaultValue);
  }, [query.loading, query.data]);
  return { data, loading: query.loading, error: query.error };
}