import React, { useContext, useEffect } from "react";

const LOGGED_IN_KEY = 'loggedIn';

export const LoggedInContext = React.createContext(getSavedLoggedIn());

export const useLoggedIn = () => {
  return useContext(LoggedInContext);
};

export function saveLoggedIn(loggedIn: boolean) {
  window.localStorage.setItem(LOGGED_IN_KEY, JSON.stringify(loggedIn));
}

export function getSavedLoggedIn(): boolean {
  try {
    const loggedIn = JSON.parse(window.localStorage.getItem(LOGGED_IN_KEY));
    return !!loggedIn;
  } catch(err) {
    return false;
  }
}