import { useEffect } from "react";

export function useKonamiCode(cb: Function) {
  const KONAMI_CODE = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
  let cursor = 0;

  useEffect(()=> {
    const onKeyDown = (e) => {
      cursor = (e.keyCode == KONAMI_CODE[cursor]) ? cursor + 1 : 0;
      if (cursor == KONAMI_CODE.length) {
        cb();
        cursor = 0;
      }
    };

    document.addEventListener('keydown', onKeyDown);
  
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);
}