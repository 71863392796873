export const Config = {
  loginURL: get<string>("loginURL", "http://localhost:8081/login"),
  logoutURL: get<string>("logoutURL", "http://localhost:8081/logout"),
  graphQLEndpoint: get<string>("graphQLEndpoint", "http://localhost:8081/api/v1/graphql"),
  subscriptionEndpoint: get<string>("subscriptionEndpoint", "ws://localhost:8081/api/v1/graphql"),
  conferenceHeartbeatInterval: get<number>("conferenceHeartbeatInterval", 10000),
  frontendBaseURL: get<string>("frontendBaseURL", window.location.protocol + '//' + window.location.host + '/'),
};

function get<T>(key: string, defaultValue: T):T {
  const config = window['__CONFIG__'] || {};
  if (config && config.hasOwnProperty(key)) {
    return config[key] as T;
  } else {
    return defaultValue;
  }
} 