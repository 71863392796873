import { Config } from './config';

declare var __webpack_public_path__: string;
__webpack_public_path__ = Config.frontendBaseURL;

import './sass/_all.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import './resources/favicon.png';


ReactDOM.render(
  <App />,
  document.getElementById('app')
);
